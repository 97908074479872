import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import styles from './index.module.less';

function Loading ({ style }) {
  return (
    <div className={styles.loading}>
      <div className={styles['loading-mark']} style={style} />
      <Spin
        className={styles['loading-spin']}
        tip="Loading..."
      />
    </div>
  );
}
Loading.propTypes = {
  style: PropTypes.object,
};

export default Loading;
