import React from 'react';
import PropTypes from 'prop-types';
import { router, routerRedux, useSelector } from 'dva';
import RouteWithSubRoutes from '@/components/Route/RouteWithSubRoutes';
import IntlProvider from '@components/IntlProvider';
import AntdConfigProvider from '@components/AntdConfigProvider';
import routesConfig from '@/config/routes';
import { createDavDynamicRoutes } from '@/utils/router';
import '@/assets/style/app.less';

const { Switch } = router;
const { ConnectedRouter } = routerRedux;

function App ({ history, app }) {
  const dvaRoutesConfig = React.useMemo(() => createDavDynamicRoutes(app, routesConfig), [app]);
  const locale = useSelector(store => store.root.locale);
  return (
    <ConnectedRouter history={history}>
      <IntlProvider locale={locale}>
        <AntdConfigProvider locale={locale}>
          <Switch>
            {dvaRoutesConfig.map((route) => (
              <RouteWithSubRoutes key={route.path} {...route} />
            ))}
          </Switch>
        </AntdConfigProvider>
      </IntlProvider>
    </ConnectedRouter>
  );
}

App.propTypes = {
  history: PropTypes.object,
  app: PropTypes.object,
};

export default App;
