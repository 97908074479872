import { dynamic } from 'dva';
import get from 'lodash/get';
import pick from 'lodash/pick';
import Loading from '../components/Loading';

function createDavDynamicRoutes (app, routes) {
  return routes.map((route) => {
    return {
      ...route,
      component: dynamic({
        app,
        models: () => (route.models || []).map((importModel) => importModel()),
        component: route.component,
        LoadingComponent: Loading,
      }),
      ...(route.routes ? { routes: createDavDynamicRoutes(app, route.routes) } : null),
    };
  });
}

function flattenRoutes (routeTree = []) {
  let routes = [];
  function flatten (tree, parent = null) {
    tree.forEach((leaf) => {
      const route = { ...leaf, breadcrumb: [...(get(parent, 'breadcrumb', [])), pick(leaf, ['name', 'path', 'intl'])] };
      routes.push(route);
      if (leaf.routes) {
        flatten(leaf.routes, route);
      }
    });
  }
  flatten(routeTree);
  return routes;
}

export {
  createDavDynamicRoutes,
  flattenRoutes,
};
