import React from 'react';
import PropTypes from 'prop-types';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import enUS from 'antd/lib/locale/en_US';
import zhTw from 'antd/lib/locale/zh_TW';

const locales = {
  'zh-CN': zhCN,
  'zh-TW': zhTw,
  'en-US': enUS,
};

function AntdConfigProvider ({ children, locale }) {
  // const [locales, setLocales] = React.useState({});
  // React.useEffect(() => {
  //   if (!locales[locale]) {
  //     import('antd/lib/locale/'+locale.replace('-', '_')) // eslint-disable-line
  //       .then((d) => setLocales((prev) => ({
  //         ...prev,
  //         [locale]: d.default,
  //       })));
  //   }
  // }, [locale]);
  return <ConfigProvider locale={locales[locale]}>{children}</ConfigProvider>;
}

AntdConfigProvider.propTypes = {
  children: PropTypes.any,
  locale: PropTypes.string,
};

export default AntdConfigProvider;
