import '@/polyfill';
import React from 'react';
import dva from 'dva';
import root from '@/models/root';
import App from '@/App';
// import reportWebVitals from '@/reportWebVitals';
import createLoading from '@/utils/createLoading';

// 1. Initialize
const app = dva();

// 2. Plugins
app.use(createLoading());

// 3. Model
app.model(root);

// 4. Router
app.router(({ ...props }) => <App {...props} />);

// 5. Start
app.start('#root');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

export default app._store;
