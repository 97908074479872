const defaultLocale = 'zh-CN';
const locales = {
  'zh-CN': import('@/i18n/zh-CN'),
  'zh-TW': import('@/i18n/zh-TW'),
  'en-US': import('@/i18n/en-US'),
};
const supportLocales = [
  { value: 'zh-CN', name: '简体中文' },
  { value: 'zh-TW', name: '繁體中文' },
  { value: 'en-US', name: 'English' },
];

export {
  locales,
  supportLocales,
  defaultLocale,
};
