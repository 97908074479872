import React from 'react';
import { useSelector, useLocation } from 'dva';
import isArray from 'lodash/isArray';

// 权限校验 hook
const usePermission = () => {
  const menuInfo = useSelector(store => store.root.menuInfo);
  const isAdmin = useSelector(store => store.root.isAdmin);
  const location = useLocation();
  const api = React.useMemo(() => {
    const permissionList = menuInfo.filter(d => d.type == 'PAGEELEMENTS').map(d => d.code);
    return {
      hasPageElementPermission: needPermission => isAdmin || permissionList.indexOf(needPermission) !== -1,
      hasRoutePermission: needPermissions => (
        !needPermissions || isAdmin || isArray(needPermissions) && menuInfo.filter((d) => needPermissions.indexOf(d.url) !== -1).length > 0
      ),
    };
  }, [menuInfo, location.pathname]);

  return api;
};

export default usePermission;
