import React from 'react';
import { router } from 'dva';
import { Result, Button } from 'antd';
import intl from 'react-intl-universal';

const { Link } = router;

const HTTP_404 = () => (
  <Result
    status="404"
    title="404"
    subTitle={intl.get('message.HTTP_404')}
    extra={(
      <Button type="primary">
        <Link to="/" replace>
          {intl.get('common._BACK_HOME_')}
        </Link>
      </Button>
    )}
  />
);

export default HTTP_404;
