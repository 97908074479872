import axios from 'axios';
import { message } from 'antd';
import Cookies from 'js-cookie';
import storage from '@/utils/storage';

function errorMsg (msg) {
  const hide = message.error({
    content: msg,
    // duration: 5 * 1000,
  });
  setTimeout(hide, 2500);
}

const instance = axios.create({
  baseURL: window.__MICRO_APP_ENVIRONMENT__
    ? window.microAppConfig.apiUrl
    : '',
});

// request interceptor
instance.interceptors.request.use(
  (config) => {
    const jwt = Cookies.get('jwt');
    if (jwt) {
      // config.headers['access-jwt'] = jwt;
    }
    // config.cancelToken = new axios.CancelToken(() => {});
    // if (typeof config.getCancel === 'function') {
    //   config.cancelToken = new axios.CancelToken(config.getCancel);
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// response interceptor
instance.interceptors.response.use(
  (response) => {
    const { headers, data: res } = response;
    // if (headers['access-jwt']) {
    //   Cookies.set('jwt', headers['access-jwt']);
    // }
    headers.client_id && storage.setItem('client_id', headers.client_id); // eslint-disable-line
    headers.client_secret && storage.setItem('client_secret', headers.client_secret); // eslint-disable-line
    // if the custom code is not 20000, it is judged as an error.
    // if (res.code === '401') {
    //   window.location.href = `${res.loginUrl}?service=${encodeURIComponent(window.location.origin)}`; // eslint-disable-line
    // } else
    if ('code' in res && res.code !== '0' && res.code !== null) {
      const error = res.msg || `Error: ${res.code}`;
      errorMsg(error);
      return Promise.reject(error);
    }
    return res;
  },
  (error) => {
    errorMsg(error.message);
    return Promise.reject(error);
  },
);

export default instance;
