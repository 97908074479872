import apiFactory, { v2 } from '../apiFactory';

export default apiFactory(
  'account',
  [
    'queryAccountPageList',
    'addAccount',
    'updateAccount',
    'queryInfosByUser',
    'changePassword',
    'resetAccountPassword',
    'getAccountInfo',
    'voidAccount',
    'logout',
    'getLoginUser',
    'checkLogin',
    'login',
    'validateLoginStatus',
    'queryWarehoseFromUser',
    'grantWarehoseToUser',
    'queryOnlineAccountList',
    'loginByCode',
    'modifyUserPassword',
    'deleteAccount',
    'queryTenantListByAccount',
    'queryPasswordPolicyPageList',
    'savePasswordPolicy',
    'updatePasswordPolicy',
  ],
  v2,
);
