import Cookies from 'js-cookie';

if (window.location.hostname.indexOf('.winit.com.cn') !== -1) {
  Cookies.defaults.domain = '.winit.com.cn';
}
const prefix = 'iam_storage_';

export default {
  setItem (key, value) {
    return Cookies.set(`${prefix}${key}`, value);
  },
  getItem (key) {
    return Cookies.get(`${prefix}${key}`);
  },
  removeItem (key) {
    Cookies.remove(`${prefix}${key}`);
  },
};
