import apiFactory, { v2 } from '../apiFactory';

export default apiFactory(
  'tenant',
  [
    'addTenant',
    'queryTenantById',
    'queryTenantList',
    'updateTenant',
  ],
  v2,
);
