// import React from 'react';
import PropTypes from 'prop-types';
import usePermission from './usePermission';

function PermissionCheck ({ permission, children, fallback = null }) {
  const { hasRoutePermission, hasPageElementPermission } = usePermission();
  // 支持自定义渲染方法
  if (typeof children === 'function') return children({ hasRoutePermission, hasPageElementPermission });

  if (!permission
    || hasRoutePermission(permission)
    || hasPageElementPermission(permission)) return children;

  return fallback;
}
PermissionCheck.propTypes = {
  permission: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  fallback: PropTypes.node,
};

export default PermissionCheck;
