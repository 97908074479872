const routes = [
  {
    path: '/login',
    name: '登录',
    intl: 'common._HOME_',
    component: () => import('@/pages/login/index.jsx'),
  },
  {
    path: '/',
    name: '首页',
    intl: 'common._HOME_',
    component: window.__MICRO_APP_ENVIRONMENT__ || (window.location.search || '').indexOf('micro') != -1
      ? () => import('@/layouts/MicroLayout.jsx')
      : () => import('@/layouts/BasicLayout.jsx'),
    models: [
      // () => import('@/models/basicLayout'),
    ],
    routes: [
      {
        path: '/tenant',
        name: '租户',
        intl: 'common._TENANT_',
        permission: ['/tenant'],
        component: () => import('@/pages/tenant/index.jsx'),
      },
      {
        path: '/application',
        name: '应用',
        intl: 'common._APP_',
        permission: ['/application'],
        // exact: true,
        component: () => import('@/pages/application/index.jsx'),
      },
      {
        path: '/organization',
        name: '组织',
        intl: 'common._ORGANIZATION_',
        permission: ['/organization'],
        // exact: true,
        component: () => import('@/pages/organization/index.jsx'),
      },
      {
        path: '/role',
        name: '角色',
        intl: 'common._ROLE_',
        permission: ['/role'],
        // exact: true,
        component: () => import('@/pages/role/index.jsx'),
      },
      {
        path: '/resource',
        name: '资源',
        intl: 'common._RESOURCE_',
        permission: ['/resource'],
        exact: true,
        component: () => import('@/pages/resource/index.jsx'),
        routes: [
          {
            path: '/resource/permissionOverview/:id/:name',
            name: '权限预览',
            intl: 'common._PERMISSION_OVERVIEW_',
            // permission: ['/account/permissionOverview'],
            // exact: true,
            component: () => import('@/pages/resource/permissionOverview.jsx'),
          },
        ],
      },
      {
        path: '/permission',
        name: '权限',
        intl: 'common._PERMISSIONS_',
        permission: ['/permission'],
        exact: true,
        component: () => import('@/pages/permission/index.jsx'),
        routes: [
          {
            path: '/permission/pageElement/:id',
            name: '页面权限',
            intl: 'common._PAGE_PERMISSIONS_',
            permission: ['/permission/pageElement'],
            // exact: true,
            component: () => import('@/pages/permission/pageElement.jsx'),
          },
          {
            path: '/permission/pageElementOld/:id',
            name: '页面权限',
            intl: 'common._PAGE_PERMISSIONS_',
            permission: ['/permission/pageElement'],
            // exact: true,
            component: () => import('@/pages/permission/pageElement_old.jsx'),
          },
          {
            path: '/permission/data/:id',
            name: '数据权限',
            intl: 'common._DATA_PERMISSIONS_',
            permission: ['/permission/data'],
            // exact: true,
            component: () => import('@/pages/permission/data.jsx'),
          },
        ],
      },
      {
        path: '/accountGroup',
        name: '用户组',
        intl: 'common._USER_GROUP_',
        permission: ['/accountGroup'],
        // exact: true,
        component: () => import('@/pages/accountGroup/index.jsx'),
      },
      {
        path: '/account',
        name: '用户',
        intl: 'common._USER_',
        permission: ['/account'],
        exact: true,
        component: () => import('@/pages/account/index.jsx'),
        routes: [
          {
            path: '/account/permissionOverview/:id',
            name: '权限预览',
            intl: 'common._PERMISSION_OVERVIEW_',
            // permission: ['/account/permissionOverview'],
            exact: true,
            component: () => import('@/pages/account/permissionOverview.jsx'),
          },
        ],
      },
      {
        path: '/accountType',
        name: '用户类型',
        intl: 'common._USER_TYPE_',
        permission: ['/accountType'],
        // exact: true,
        component: () => import('@/pages/accountType/index.jsx'),
      },
      {
        path: '/warehouse',
        name: '仓库管理',
        intl: 'common._USER_TYPE_',
        permission: ['/warehouse'],
        // exact: true,
        component: () => import('@/pages/warehouse/index.jsx'),
      },
      {
        path: '/log',
        name: '日志',
        intl: 'common._LOG_',
        permission: ['/log'],
        // exact: true,
        component: () => import('@/pages/audit/log.jsx'),
      },
      {
        path: '/category',
        name: '类别',
        intl: 'common._CATEGORY_',
        permission: ['/category'],
        // exact: true,
        component: () => import('@/pages/category/index.jsx'),
      },
      {
        path: '/tools',
        name: '系统工具',
        intl: 'common._SYSTEM_TOOL_',
        permission: ['/tools'],
        // exact: true,
        component: () => import('@/pages/tools/index.jsx'),
      },
      {
        path: '/LMSPermission/account',
        name: '用户管理',
        component: () => import('@/pages/lmsportal/account/index.jsx'),
        intl: 'common._PERMISSION_OVERVIEW_',
      },
      {
        path: '/LMSPermission/role',
        name: '角色管理',
        component: () => import('@/pages/lmsportal/role/index.jsx'),
        intl: 'common._PERMISSION_OVERVIEW_',
        exact: true,
        routes: [
          {
            path: '/LMSPermission/role/pageElement/:id',
            name: '页面权限',
            intl: 'common._PAGE_PERMISSIONS_',
            // exact: true,
            component: () => import('@/pages/permission/pageElement.jsx'),
          },
        ],
      },
      {
        path: '/LMSPermission/subAccount',
        name: '账号管理',
        component: () => import('@/pages/lmsportal/account/subAccount.jsx'),
        intl: 'common._ACCOUNT_MANAGEMENT_',
      },
      {
        path: '/passwordPolicy',
        name: '密码策略',
        component: () => import('@/pages/passwordPolicy/index.jsx'),
        intl: 'common._PASSWORD_POLICY_',
        permission: ['/passwordPolicy'],
      },
    ],
  },
];

export default routes;
