import request from '@/utils/request';
import { getLocale } from '@/utils/locale';
import cloneDeep from 'lodash/cloneDeep';
import MD5 from 'crypto-js/md5';
import Cookies from 'js-cookie';
import has from 'lodash/has';
import queryString from 'query-string';
import { getLocalLoginUrl } from '@/common/functions';
import storage from '@/utils/storage';

const v1 = '1.0';
const v2 = '2.0';

function ksort (obj) {
  if (Object.prototype.toString.call(obj) === '[object Object]') {
    const keys = Object.keys(obj);
    const res = {};
    keys.sort().forEach((key) => {
      res[key] = ksort(obj[key]);
      if (res[key] === null) {
        delete res[key];
      }
    });
    return res;
  }
  if (Object.prototype.toString.call(obj) === '[object Array]') {
    return obj.map(item => ksort(item));
  }
  if (typeof obj === 'string') {
    return obj.trim();
  }
  return obj;
}

function getSignData (data, token) {
  const clientSecret = process.env.REACT_APP_IAM_API_CLIENT_SECRET;
  const json = JSON.stringify(ksort(cloneDeep(data.data)));
  json.replace(/\\\//g, '/').replace('":null', ':""');

  const sign = MD5(
    `${token}action${data.action}app_key${data.app_key}`
      + `data${json}format${data.format}platform${data.platform}`
      + `sign_method${data.sign_method}timestamp${data.timestamp}`
      + `version${data.version}${token}`,
  ).toString().toUpperCase();
  const clientSign = MD5(
    `${clientSecret}action${data.action}`
      + `app_key${data.app_key}data${json}format${data.format}`
      + `platform${data.platform}sign_method${data.sign_method}`
      + `timestamp${data.timestamp}version${data.version}`
      + `${clientSecret}`,
  ).toString().toUpperCase();

  data.sign = sign;
  data.client_sign = clientSign;

  return data;
}

function sendRequest (data) {
  return request({
    method: 'post',
    url: `${process.env.REACT_APP_IAM_API}${data.action}`,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
}

function getRequestData (action, version, params) {
  return {
    action,
    app_key: (version === v2 ? storage.getItem('app_key_v2') : storage.getItem('app_key')) || '',
    client_id: process.env.REACT_APP_IAM_API_CLIENT_ID,
    user_name: storage.getItem('app_key'),
    data: {
      ...params,
      userTypeId: action !== '/account/queryTenantListByAccount'
        ? has(params, 'userTypeId')
          ? params.userTypeId
          : storage.getItem('userTypeId')
        : undefined,
    },
    format: 'json',
    language: getLocale(),
    platform: 'iam',
    sign_method: 'md5',
    tenantId: storage.getItem('tenantId'),
    timestamp: Date.now(),
    version,
  };
}

async function getToken (force = false) {
  let tokenCache = null;
  try {
    tokenCache = JSON.parse(storage.getItem('token') || null);
  } catch (e) {
    tokenCache = null;
  }
  const jwt = Cookies.get('jwt');
  const appKey = storage.getItem('app_key');
  if (tokenCache && (tokenCache.expireDate - Date.now() < 2 * 60 * 1000 || appKey !== tokenCache.appKey)
    || !tokenCache && jwt
    || jwt && force) {
    const { data: tokenInfo } = await sendRequest(getSignData(getRequestData('/oauth/getToken', v2, {
      jwt,
    })));
    storage.setItem('token', JSON.stringify({
      token: tokenInfo.access_token,
      expireDate: new Date(tokenInfo.expire_date).getTime(),
      appKey,
    }));
    return tokenInfo.access_token;
  }
  return tokenCache && tokenCache.token;
}

async function checkLogin (isJumpLoginPage = true) {
  const jwt = Cookies.get('jwt');
  // if (!jwt) return Promise.reject();
  try {
    const res = await sendRequest(getSignData(getRequestData('/account/validateLoginStatus', v2, {
      jwt,
      callback_url: window.location.origin,
    })));
    const { data: checkInfo } = res;
    if (checkInfo.is_login) {
      // checkInfo.client_id && storage.setItem('client_id', checkInfo.client_id);
      // checkInfo.client_secret && storage.setItem('client_secret', checkInfo.client_secret);
      if (checkInfo.username) {
        storage.setItem('app_key', checkInfo.username);
        storage.setItem('userName', checkInfo.username);
      }
      checkInfo.jwt && Cookies.set('jwt', checkInfo.jwt);
      return res;
    }
    if (isJumpLoginPage) {
      const loginUrl = process.env.NODE_ENV === 'development'
        ? getLocalLoginUrl()
        : checkInfo.callback_url;
      const query = queryString.parseUrl(window.location.href)?.query || {};
      window.location.href = queryString.stringifyUrl({ url: loginUrl, query });
      return Promise.reject();
    }
    return Promise.resolve(res);
  } catch (e) {
    return Promise.reject();
  }
}

function accountLogin (params) {
  return sendRequest(getSignData({ ...getRequestData('/account/login', v2, params), ...params }));
}

function accountLoginByCode (params) {
  return sendRequest(getSignData({ ...getRequestData('/account/loginByCode', v2), ...params }));
}

const checkLoginIgnore = [
  '/account/validateLoginStatus',
  '/account/login',
  '/account/loginByCode',
  '/application/getLoginConfig',
  '/account/queryTenantListByAccount',
];
function apiFactory (serviceName, methods = [], version = '') {
  return methods.reduce((service, method) => {
    service[method] = (params) => {
      const action = `/${serviceName}/${method}`;
      const data = getRequestData(action, version, params);
      return (checkLoginIgnore.indexOf(action) !== -1 ? Promise.resolve() : checkLogin().then(() => getToken()))
        .then((token) => sendRequest(getSignData(data, token)));
    };
    return service;
  }, {});
}

export default apiFactory;
export {
  v1,
  v2,
  checkLogin,
  getToken,
  accountLogin,
  accountLoginByCode,
};
