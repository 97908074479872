import React from 'react';
import { router } from 'dva';
import pick from 'lodash/pick';
import PermissionCheck from '@components/Permission';
import { HTTP_403 } from '@components/StatusPage';

const { Route } = router;

function RouteWithSubRoutes (route) {
  return (
    <Route
      path={route.path}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <PermissionCheck permission={route.permission}
          fallback={<HTTP_403 />}
        >
          <route.component {...props} {...pick(route, ['routes', 'match'])} />
        </PermissionCheck>
      )}
    />
  );
}

export default RouteWithSubRoutes;
