import { setLocale, getLocale } from '@/utils/locale';
import Cookies from 'js-cookie';
import get from 'lodash/get';
import find from 'lodash/find';
import AccountApi from '@/api/iam/v2/AccountApi';
import AccountTypeApi from '@/api/iam/v2/AccountTypeApi';
import AreaApi from '@/api/iam/v2/AreaApi';
import TenantApi from '@/api/iam/v2/TenantApi';
import { getLocalLoginUrl, isVerifyLogin } from '@/common/functions';
import storage from '@/utils/storage';

const tenantAdminUserType = 3;

export default {
  namespace: 'root',

  state: {
    title: 'IAM',
    locale: getLocale(),
    userInfo: {},
    menuInfo: [],
    accountTypeList: [],
    areaList: [],
    tenantList: [],
    isAdmin: false,
  },

  subscriptions: {
    setup ({ dispatch }) {
      if (window.location.hostname.indexOf('.winit.com.cn') !== -1) {
        Cookies.defaults.domain = '.winit.com.cn';
      }
      if (window.__MICRO_APP_ENVIRONMENT__) {
        // 微应用 多语言设置
        const locale = window?.microAppConfig?.language ?? 'zh-cn';
        const languageMatch = locale?.match?.(/([a-zA-Z]+)/g) ?? [];
        const localeFormat = `${(languageMatch?.[0] ?? '').toLowerCase()}-${(languageMatch?.[1] ?? '').toUpperCase()}`;
        dispatch({ type: 'setLocale', locale: localeFormat });
      }
      if (isVerifyLogin()) {
        dispatch({ type: 'getLoginUser' });
      }
    },
  },

  effects: {
    * logout (_, { call }) {
      yield call(AccountApi.logout, {
        callback_url: window.location.origin,
      });
      Cookies.remove('jwt');
      Cookies.remove('user_id');
      storage.removeItem('client_id');
      storage.removeItem('client_secret');
      storage.removeItem('app_key');
      storage.removeItem('app_key_v2');
      storage.removeItem('userName');
      storage.removeItem('token');
      window.location.href = getLocalLoginUrl();
    },
    * getLoginUser (_, { call, put }) {
      try {
        const { data: accountTypeList = [] } = yield call(AccountTypeApi.queryAccountTypeList);
        const userTypeId = storage.getItem('userTypeId') * 1;
        if (!userTypeId || !find(accountTypeList, { id: userTypeId })) {
          storage.setItem('userTypeId', get(accountTypeList, '0.id') || '');
        }

        const { data: user } = yield call(AccountApi.queryInfosByUser, {
          accountName: storage.getItem('app_key'),
          systemName: 'iam',
        });

        const { data: areaList } = yield call(AreaApi.queryAreaList);

        let tenantList = [];
        if (get(user, 'userInfo.userType') == tenantAdminUserType) {
          const tenantRes = yield call(TenantApi.queryTenantList);
          tenantList = get(tenantRes, 'data') || [];
        }

        yield put({
          type: 'setUserInfo',
          user,
          accountTypeList,
          areaList,
          tenantList,
        });
      } catch (e) {
        console.log(e);
      }
    },
  },

  reducers: {
    setLocale (state, { locale: key }) {
      setLocale(key, false);
      return {
        ...state,
        locale: key,
      };
    },
    setUserInfo (state, { user, accountTypeList, areaList, tenantList }) {
      const menuInfo = ((menuTree = []) => {
        let menuList = [];
        function flatten (tree) {
          tree.forEach((leaf) => {
            menuList.push(leaf);
            if (leaf.child) {
              flatten(leaf.child);
            }
          });
        }
        flatten(menuTree);
        return menuList;
      })(get(user, 'menuInfo') || []);
      return {
        ...state,
        userInfo: get(user, 'userInfo') || [],
        menuInfo,
        accountTypeList,
        areaList: areaList || [],
        tenantList: tenantList || [],
        isAdmin: get(user, 'userInfo.userType') == tenantAdminUserType,
      };
    },
  },
};
