import { defaultLocale } from '@/config/locale';
import storage from '@/utils/storage';

const localeKey = 'locale';

function getLocale () {
  const lang = typeof storage !== 'undefined' ? storage.getItem(localeKey) : '';
  const isNavigatorLanguageValid = typeof navigator !== 'undefined'
    && typeof window.navigator.language === 'string';
  const browserLang = isNavigatorLanguageValid
    ? window.navigator.language
    : '';
  return lang || defaultLocale || browserLang;
}

function setLocale (lang, realReload = true) {
  if (lang !== undefined && !/^([a-z]{2})-([A-Z]{2})$/.test(lang)) {
    throw new Error('setLocale lang format error');
  }
  if (getLocale() !== lang) {
    storage.setItem(localeKey, lang || '');
    // 触发 context 的 reload
    // 如果要刷新 location ，没必要进行 context 的 reload 了
    // if (localeContext && !realReload) {
    //   localeContext.reloadAppLocale();
    // }
    if (realReload) {
      window.location.reload();
    }
    // chrome 不支持这个事件。所以人肉触发一下
    // if (window.dispatchEvent) {
    //   const event = new Event('languagechange');
    //   window.dispatchEvent(event);
    // }
  }
}

export {
  getLocale,
  setLocale,
};
