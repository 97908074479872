import React, { Component } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { locales } from '@/config/locale';

class IntlProvider extends Component {
  constructor (props) {
    super(props);

    this.state = {
      initDone: '',
      locale: '',
    };

    this.loadLocales = this.loadLocales.bind(this);
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (nextProps.locale !== prevState.locale) {
      return {
        initDone: 'pending',
        locale: nextProps.locale,
      };
    }
    return null;
  }

  componentDidMount () {
    this.loadLocales(this.props.locale);
  }

  componentDidUpdate (prevProps) {
    if (prevProps.locale !== this.props.locale) {
      this.loadLocales(this.props.locale);
    }
  }

  loadLocales (currentLocale) {
    new Promise((resolve) => {
      locales[currentLocale].then((data) => {
        const _locales = {};
        Promise.all(Object.keys(data).map((key) => {
          return this.loadItem(data[key]).then((d) => {
            _locales[key] = d.default;
          });
        })).then(() => {
          resolve(_locales);
        });
      });
    }).then((data) => {
      intl.init({
        currentLocale,
        locales: {
          [currentLocale]: data,
        },
      }).then(() => {
        this.setState({
          initDone: 'done',
        });
      });
    });
  }

  loadItem (data) {
    const promise = new Promise((resolve) => {
      data.then((d) => {
        resolve(d);
      });
    });
    return promise;
  }

  render () {
    if (this.state.initDone === 'done') {
      return this.props.children;
    }
    if (this.state.initDone === 'pending') {
      // 切换多语言时防止页面出现空白
      return (<div />);
    }
    return null;
  }
}
IntlProvider.propTypes = {
  children: PropTypes.any,
  locale: PropTypes.string,
};

export default IntlProvider;
