import React from 'react';
import { useSelector } from 'dva';
import Loading from './Loading';

function ResquestLoading () {
  const loading = useSelector(store => store.loading.global);
  return loading ? <Loading /> : null;
}

export default ResquestLoading;
