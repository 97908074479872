import apiFactory, { v2 } from '../apiFactory';

export default apiFactory(
  'accountType',
  [
    'addAccountType',
    'queryAccountTypeList',
  ],
  v2,
);
